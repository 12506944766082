import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import ColWrap from '../components/Helpers/ColWrap'
import Layout from '../components/Layout'

const ContactBedankt = ({ data }) => {
  const { title: siteTitle } = data.site.siteMetadata

  return (
    <Layout>
      <Helmet title={`Contact bevestiging | ${siteTitle}`} />
      <section className="section mb color-bg">
        <div className="container">
          <ColWrap>
            Bedankt voor jouw contactaanvraag. We contacteren je zo spoedig
            mogelijk.
          </ColWrap>
        </div>
      </section>
    </Layout>
  )
}

export default ContactBedankt

export const contactQuery = graphql`
  query contactBedanktQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
